import React from "react"
import './footer.css'


class Footer extends React.Component{
    /* this is declaring the variables */
   render() {
     return (
        /*here's the logic*/
        
       <div className="Footer">
        <div className="FooterBar">
          <div className="FooterGroup"> 
            <a href="mailto:john@johncrossneumann.com">email</a>
            <a href="https://twitter.com/jcrossneumann" rel="noreferrer" target="_blank">twitter</a>
            <a href="https://medium.com/@JCrossNeumann" rel="noreferrer" target="_blank">medium</a>
            <a href="https://vimeo.com/user27474679" rel="noreferrer" target="_blank">vimeo</a>
            <a href="https://www.twitch.tv/amazingmttrs" rel="noreferrer" target="_blank">twitch</a>
            <a href="https://forpublicgood.com" rel="noreferrer" target="_blank">shop</a>
            <a href="https://www.linkedin.com/in/johncrossneumann/" rel="noreferrer" target="_blank">linkedin</a>
            </div>
            <div className="FooterAuthor"> site designed and built in react by john cross neumann
        </div>
          </div>
       </div>
     )
   }
 }
 
 export default Footer