export const color = {
  blue: '#0095DD',
  red: '#C91828',
  green: '#6AED76',
  yellow: '#FFC54D',
  white: '#ffffff',
  gray1: '#e5e5e5',
  gray2: '#C8CACD',
  gray3: '#A8ADB3',
  gray4: '#848B94',
  gray5: '#636D79',
  gray6: '#3B4148',
  gray7: '#262A2F', 
  black: '#0F0E12',
  dat: '#0046e0',
}

export const space = [
  0,
  4,
  8,
  12,
  24,
  48,
  72,
  144,
  256,
];

export const fontSize = [
  "20px",
  "1.6rem",
  "2.3rem",  
  "3.2rem",
];

export const isDarkBackground = function(color) {
  if (color) {
    if (color.includes("black") || color.includes("gray2") || color.includes("gray3") || color.includes("blue") || color.includes("red")) {
      return true;
    } else {
      return false;
    }
  }
}
