import styled from 'styled-components';
import { isDarkBackground, color, space } from './constants';
import Btn from "./Button"

const BtnHeader = styled(Btn)`
    color: ${props => isDarkBackground(props.color) ? color.white : color.black};
    background-color: ${props => props.color ? color[props.color] : color.blue};
    @media (max-width: 1024px){
        width: 120px;
        padding: ${space[2] + 'px'} ${space[3] + 'px'};
    }
    @media (max-width: 640px){
        width: 96px;
        font-size: 16px;
        padding: ${space[1] + 'px'} ${space[2] + 'px'};
    }
 `

 export default BtnHeader
