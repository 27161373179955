import styled, { css } from 'styled-components';
import { isDarkBackground, color, space } from './constants';

const Btn = styled.button`
  width: 156px;
  color: ${props => isDarkBackground(props.color) ? color.white : color.black};
  outline: none;
  font-family: 'SF Pro Display', -apple-system, 'Inter', 'Helvetica Neue', sans-serif;
  font-size: 22px;
  font-weight: 300;
  display: inline-block;
  text-decoration: none;
  border: none;
  border-radius: 100px;
  transition: 0.2s all ease;
  background-color: ${props => props.color ? color[props.color] : color.blue};

  padding: ${space[3] + 'px'} ${space[4] + 'px'};

  /* <Btn size="sm">  to set button size*/
  ${props => props.size === "lg" && css`
    font-size: 18px;
    padding: ${space[3] + 4 + 'px'} ${space[5]+ 'px'};
  `}

  ${props => props.size === "sm" && css`
    font-size: 14px;
    padding: ${space[2] + 'px'} ${space[3] + 'px'};
  `}

  &:hover {
    cursor: pointer;
    box-shadow: 0 -1px 20px ${props => props.color ? color[props.color] : color.blue}80;
    transform: translateY(-1px);
  }
`
export default Btn

